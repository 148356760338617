import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {
    Typography,
    Link,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { FeedService } from "../../services/dao/feed-service";
import { PatternItem } from "./pattern-item";
import { LoadingComponent } from "../../components/loading-provider";

const logger = new Logger("PatternList");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const feedService = new FeedService();

export const PatternList = (props) => {
    const feed = props.feed;
    const [patterns, setPatterns] = useState([]);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        feedService.getFeedPatterns(feed.id).then((response) => {
            const patterns = response;
            setPatterns(patterns);
            setLoading(false);
        });
    }, []);


    return (
        <div className={classes.root}>
            {loading ? <LoadingComponent /> : (
                <>
                    {patterns?.length ? patterns.map((p) => (
                        <PatternItem key={p.id} pattern={p} />
                    )) : (
                        <Typography>No patterns created yet. <Link href="/createPattern">Create a pattern</Link> then check back here!</Typography>
                    )}
                </>
            )}
        </div >)
        ;
}
