import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {
    Chip,
    Tooltip,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'react-moment';

import { PatternService } from "../../services/dao/pattern-service";
import { LoadingComponent } from "../../components/loading-provider";

const logger = new Logger("PatternResults");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const patternService = new PatternService();

export const PatternResults = (props) => {
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const classes = useStyles();
    const patternId = props.patternId;

    useEffect(() => {
        setLoading(true);
        patternService.getPatternResults(patternId).then((response) => {
            const results = response;
            setResults(results);
            setLoading(false);
        });
    }, []);

    return (
        <div className={classes.root}>
            {loading ? <LoadingComponent /> : (
                <>
                    {results?.length ? (
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={results}
                                columns={[
                                    { field: 'src', headerName: 'Source Object', flex: 0.5 },
                                    { field: 'context', headerName: 'Context', flex: 1 },
                                    {
                                        field: 'created_date',
                                        headerName: 'Discovered',
                                        renderCell: (params) => (
                                            <Tooltip title={<Moment utc local fromNow>{params.value}</Moment>}>
                                                <Chip label={
                                                    <Moment utc local format="YYYY/MM/DD HH:mm:ss">{params.value}</Moment>
                                                } />
                                            </Tooltip>
                                        ),
                                        flex: 0.5,
                                    },
                                    {
                                        field: 'pattern_version',
                                        headerName: 'Regular Expression',
                                        valueGetter: (value) => {
                                            return value.regex;
                                        },
                                        flex: 0.5,
                                    },
                                ]}
                            />
                        </div>
                    ) : (
                        <Typography>No results yet!</Typography>
                    )}
                </>
            )}

        </div >)
        ;
}
