import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {
    TextField,
    Switch,
    Chip,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    Button,
    Stack,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import Moment from 'react-moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextareaAutosize from 'react-textarea-autosize';

import { PatternService } from "../../services/dao/pattern-service";

const logger = new Logger("PatternItem");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const patternService = new PatternService();

export const PatternItem = (props) => {
    const pattern = props.pattern;
    const [patternEnabled, setPatternEnabled] = useState(pattern.enabled);
    const [patternName, setPatternName] = useState(pattern.name);
    const [patternCurrentRegex, setPatternCurrentRegex] = useState(pattern.pattern_versions.length && pattern.pattern_versions[0].regex) || '';
    const [patternVersions, setPatternVersions] = useState(pattern.pattern_versions);
    const classes = useStyles();

    const handlePatternToggle = (event) => {
        setPatternEnabled(event.target.checked);
        patternService.updatePattern(
            pattern.id, JSON.stringify(
                { enabled: event.target.checked }
            ));
    };

    const handleUpdatePatternName = (event) => {
        setPatternName(event.target.value);
        if (event.type === 'blur') {
            if (event.target.value) {
                patternService.updatePattern(
                    pattern.id, JSON.stringify(
                        { name: event.target.value }
                    ))
            }
        }
    }

    const handleUpdatePatternRegex = (event) => {
        setPatternCurrentRegex(event.target.value);
        if (event.type === 'blur') {
            if (event.target.value) {
                patternService.createPatternVersion(
                    pattern.id, event.target.value
                ).then(response => {
                    pattern.pattern_versions.unshift(response);
                    setPatternVersions([...pattern.pattern_versions]);
                });
            }
        }
    }

    return (
        <div className={classes.root}>
            {pattern &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Stack direction="row" spacing={2}>
                            <TextField
                                required
                                value={patternName || ''}
                                label="Pattern Name"
                                onBlur={handleUpdatePatternName}
                                onChange={handleUpdatePatternName}
                            />
                            <div>
                                <label style={{ display: 'block' }}>Regular Expression
                                </label>
                                <TextareaAutosize
                                    name="regex"
                                    style={{ display: 'block' }}
                                    rows={4}
                                    value={patternCurrentRegex || ''}
                                    onBlur={handleUpdatePatternRegex}
                                    onChange={handleUpdatePatternRegex}
                                />
                            </div>
                            <Switch
                                edge="end"
                                onChange={handlePatternToggle}
                                checked={patternEnabled}
                            />
                            <Button href={`/patterns/${pattern.id}`}>View Pattern Results</Button>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Previous regular expressions</Typography>
                        <List>
                            {patternVersions && patternVersions.map((pv) => (
                                <ListItem key={pv.id}>
                                    <TextareaAutosize
                                        disabled
                                        rows={4}
                                        value={pv.regex}
                                    />
                                    <Tooltip title={<Moment utc local fromNow>{pv.created_date}</Moment>}>
                                        <Chip label={
                                            <Moment utc local format="YYYY/MM/DD HH:mm:ss">{pv.created_date}</Moment>
                                        } />
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            }
        </div >)
        ;
}
