import { Logger } from "@aws-amplify/core";
import { config } from "../constants";
import { AuthService } from "../../screens/auth/service/auth-service";

const logger = new Logger("PatternService");

export class PatternService {

    getPatternVersion = async (patternVersionId) => {

        let response = null;
        const endpoint = `${config.appUrl}/pattern_versions/${patternVersionId}`

        logger.debug("getPatternVersion() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got pattern version', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get pattern version", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getPattern = async (patternId) => {

        let response = null;
        const endpoint = `${config.appUrl}/patterns/${patternId}`

        logger.debug("getPattern() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got pattern', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get pattern", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getPatternVersionResults = async (patternVersionId) => {

        let response = null;
        const endpoint = `${config.appUrl}/pattern_versions/${patternVersionId}/results`

        logger.debug("getPatternVersionResults() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got pattern version results', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get pattern version results", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getPatternResults = async (patternId) => {

        let response = null;
        const endpoint = `${config.appUrl}/patterns/${patternId}/results`

        logger.debug("getPatternResults() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got pattern results', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get pattern results", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    updatePattern = async (patternId, requestBody) => {

        let response = null;
        const endpoint = `${config.appUrl}/patterns/${patternId}`

        logger.debug("updatePattern() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'PATCH',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json',
                },
                mode: "cors",
                body: requestBody,
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('updated pattern', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't update pattern", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    createPattern = async (requestBody) => {

        let response = null;
        const endpoint = `${config.appUrl}/patterns`

        logger.debug("createPattern() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json',
                },
                mode: "cors",
                body: requestBody,
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('created pattern', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't create pattern", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    createPatternVersion = async (patternId, regex) => {

        let response = null;
        const endpoint = `${config.appUrl}/pattern_versions`
        const requestBody = JSON.stringify({
            'regex': regex,
            'pattern_id': patternId,
        });

        logger.debug("createPatternVersion() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json',
                },
                mode: "cors",
                body: requestBody,
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('created pattern version', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't create pattern version", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    deletePattern = async (patternId) => {

        let response = null;
        const endpoint = `${config.appUrl}/patterns/${patternId}`

        logger.debug("deletePattern() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'DELETE',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then((response) => {
                if (response.status === 204) {
                    logger.debug('deleted pattern', patternId) // access json.body here
                } else {
                    logger.error("Couldn't delete pattern", ex)
                }
            });
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

}
