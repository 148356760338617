import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Container, Grid, Divider } from "@mui/material";
import { Logger } from "@aws-amplify/core";
import {
    Typography,
    Link,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";


const logger = new Logger("Info");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    },
    image: {
        backgroundImage: "url('images/PMaaS.png')",
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

export const InfoPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container
                justifycontent="center"
                alignitems="center"
                spacing={1}
                xl={12}
                sm={12}
                sx={{ p: 1 }}
                style={{ width: "100%" }}
            >
                <ThemeProvider theme={theme}>
                    <Typography variant="h3">What is Pattern Matching Service?</Typography>
                </ThemeProvider>
                <Divider />
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            This application provides a service to analyze your data for patterns
                            in a highly performant manner. First, you will define where your data is located (currently,
                            only AWS S3 Buckets are supported) and then define a set of regular expressions to perform against your data feed.
                            As your data feed changes, this service will identify where regular expressions have found matches in your data.
                        </Typography>
                        <img src={"./images/PMaaS.png"} width="800" height="500" />
                        <Typography variant="h5">Why?</Typography>
                        <Typography>Many organizations need scalable pattern matching solutions for continuously changing data sets. Rather than build your own data analysis pipeline, we have abstracted the process
                            into this application. This application has optimized the pattern matching process to handle very large data sets and objects, as well as a multitude of complex regular expressions evaluated in parallel.
                            A REST API is provided to view results when patterns are matched in your data. This allows you to build your own alerting process when matches are detected.
                        </Typography>
                        <Typography variant="h5">Security</Typography>
                        <Typography>
                            To ensure the security of your data, your data is never persisted during the
                            analysis process. When matches occur on your patterns, the application will store a small amount of text on the line where the match occurred.
                            This is returned in the results API call as well as the user interface as the "context".
                        </Typography>
                        <Typography>For more information on security, please <Link href="mailto:info@atomweight.io">contact us</Link>.</Typography>

                    </Grid>
                </Grid>

            </Container>

        </div>)
        ;
}
