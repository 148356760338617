import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
    Alert,
    Container,
    Box,
    Button,
    TextField,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FeedService } from "../../services/dao/feed-service";

const logger = new Logger("CreateFeed");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    },
    AccordionSummary: {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
    },
}));

const feedService = new FeedService();

export const CreateFeed = () => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Feed name is required'),
        src: Yup.string().required('Feed source is required'),
    });
    const { register, control, handleSubmit, formState: { errors } } = useForm(
        { resolver: yupResolver(validationSchema) });
    const [error, setError] = React.useState(false);

    const onSubmit = data => {
        setLoading(true);
        feedService.createFeed(
            JSON.stringify({ ...data, enabled: false }),
        ).then(response => {
            if (response.hasOwnProperty('error') && response.error.includes('error connecting to feed with bucket name')) {
                setError(true);
                setLoading(false);
            } else {
                history.push('/');
            }
        }).catch(error => {
            // TODO
        });
    };
    const s3Policy = `
    {
        "Version": "2012-10-17",
        "Statement": [
            {
                "Sid": "Allow pattern matching service",
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::877744955130:root"
                },
                "Action": [
                    "s3:GetObject",
                    "s3:ListBucket"
                ],
                "Resource": [
                    "arn:aws:s3:::<YOUR BUCKET NAME>",
                    "arn:aws:s3:::<YOUR BUCKET NAME>/*"
                ]
            }
        ]
    }
    `;

    return (
        <div className={classes.root}>
            <Container
                justifycontent="center"
                alignitems="center"
                spacing={5}
                xl={12}
                sm={12}
                sx={{ p: 5 }}
                style={{ width: "100%" }}
            >
                <Box px={3} py={2}>
                    <Typography variant="h3">
                        Create Feed
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {error &&
                                <Alert severity="error">Could not connect to your S3 bucket specified. Ensure that you have followed the instructions under the "What is a feed?" section to grant access. Also ensure that the bucket is not empty.</Alert>
                            }
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    What is a feed?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        A <strong>feed</strong> is an <a href="https://aws.amazon.com/s3/">AWS S3 Bucket</a> where your files will be analyzed for patterns. Therefore, you must have an AWS account and S3 Bucket before creating a feed.
                                        To allow access for us to analyze your files, the following S3 Bucket policy must be set on your bucket. Ensure to replace <strong>{'<'}YOUR BUCKET NAME{'>'}</strong> before saving the policy.
                                    </Typography>
                                    <code style={{ display: "block", whiteSpace: "pre-wrap" }}>{s3Policy}</code>
                                    <Typography>See the <a href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/add-bucket-policy.html">AWS documentation</a> for additional guidance.</Typography>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Feed Name"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={errors.name ? true : false}
                            />
                            <Typography variant="inherit" color="textSecondary">
                                {errors.name?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="src"
                                name="src"
                                label="Feed Source"
                                fullWidth
                                margin="dense"
                                {...register('src')}
                                error={errors.src ? true : false}
                                helperText="Name of the AWS S3 bucket"
                            />
                            <Typography variant="inherit" color="textSecondary">
                                {errors.src?.message}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Create Feed
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div >)
        ;
}
