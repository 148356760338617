import React, { useState, useEffect } from 'react';

import {
    Alert,
    Link,
    Chip,
    Tooltip,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'react-moment';

import { FeedService } from "../../services/dao/feed-service";
import { LoadingComponent } from "../../components/loading-provider";

const logger = new Logger("FeedResultsDataGrid");
const feedService = new FeedService();

export const FeedResultsDataGrid = (props) => {
    const feedId = props.feedId;
    const feedEnabled = props.feedEnabled;
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setLoading(true);
        feedService.getFeedResults(feedId).then((response) => {
            const results = response;
            setResults(results);
            setLoading(false);

        });
    }, []);

    return (
        <>
            {loading ? <LoadingComponent /> : (
                <>
                    {!feedEnabled && !results?.length && <Alert severity="error">This feed is currently disabled. Maybe you want to <Link href={`/feeds/${feedId}`} >enable it</Link> to start seeing results?</Alert>}
                    {results?.length ? (
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={results}
                                columns={[
                                    { field: 'src', headerName: 'Source Object', flex: 0.5 },
                                    { field: 'context', headerName: 'Context', flex: 1 },
                                    {
                                        field: 'created_date',
                                        headerName: 'Discovered',
                                        renderCell: (params) => (
                                            <Tooltip title={<Moment utc local fromNow>{params.value}</Moment>}>
                                                <Chip label={
                                                    <Moment utc local format="YYYY/MM/DD HH:mm:ss">{params.value}</Moment>
                                                } />
                                            </Tooltip>
                                        ),
                                        flex: 0.5,
                                    },
                                    {
                                        field: 'pattern_name',
                                        headerName: 'Pattern Name',
                                        valueGetter: (value, row) => {
                                            return row?.pattern_version?.pattern?.name;
                                        },
                                        flex: 0.5,
                                    },
                                    {
                                        field: 'pattern_version',
                                        headerName: 'Regular Expression',
                                        valueGetter: (value) => {
                                            return value.regex;
                                        },
                                        flex: 0.5,
                                    },
                                ]}
                            />
                        </div>
                    ) : (
                        <Typography>No results yet!</Typography>
                    )}
                </>
            )}
        </>
    );
}
