import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import {
    Chip,
    Tooltip,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";

import { FeedService } from "../../services/dao/feed-service";

const logger = new Logger("FeedMetrics");


const feedService = new FeedService();

export const FeedMetrics = (props) => {
    const feedId = props.feedId;
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState({});

    useEffect(() => {
        setLoading(true);
        feedService.getFeedMetrics(feedId).then((response) => {
            const metrics = response;
            setMetrics(metrics);
            setLoading(false);
        });
    }, []);


    return (
        <>
            {loading ? <Chip label="loading..." /> : (
                <>
                    <Tooltip title="View targets">
                        <Link to={`/feedTargets/${feedId}`}>
                            {metrics['targets_analyzed'] &&
                                <Chip
                                    label={`${metrics['targets_analyzed']['true']}/${metrics['targets_analyzed']['total']} objects analyzed`}
                                    color={metrics['targets_analyzed']['true'] === metrics['targets_analyzed']['total'] ? 'success' : 'warning'}
                                />
                            }
                        </Link>
                    </Tooltip>
                </>
            )}
        </>)
        ;
}
