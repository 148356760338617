import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import {
    Container,
    Grid,
    Paper,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { FeedService } from "../../services/dao/feed-service";
import { useLoading, LoadingComponent } from "../../components/loading-provider";
import { FeedResultsDataGrid } from './feed-results-datagrid';
import { BreadCrumbs } from '../shared/components/app-breadcrumbs';

const logger = new Logger("FeedResults");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const feedService = new FeedService();

export const FeedResults = () => {
    const [feedName, setFeedName] = useState('');
    const { loading, setLoading } = useLoading();
    const [feedEnabled, setFeedEnabled] = useState(true);
    const classes = useStyles();
    const { feedId } = useParams();

    useEffect(() => {
        setLoading(true);
        feedService.getFeed(feedId).then((response) => {
            setFeedName(response.name);
            setFeedEnabled(response.enabled);
            setLoading(false);
        });
    }, []);

    return (
        <div className={classes.root}>
            {loading ? <LoadingComponent /> : (
                <>
                    {feedName &&
                        <Container
                            justifycontent="center"
                            alignitems="center"
                            spacing={5}
                            xl={12}
                            sm={12}
                            sx={{ p: 5 }}
                            style={{ width: "100%" }}
                        >
                            <Grid container spacing={2} rowSpacing={2}>
                                <Grid xs={12} item>
                                    <BreadCrumbs links={{ [feedName]: `/feeds/${feedId}`, 'Feed Results': '' }} />
                                    <ThemeProvider theme={theme}>
                                        <Typography variant="h3">{`Feed - ${feedName}`}</Typography>
                                    </ThemeProvider>
                                </Grid>
                                <Grid xs={12} item>
                                    <Paper sx={{ p: 5 }}>
                                        <Typography variant="h4">Results</Typography>
                                        <FeedResultsDataGrid feedId={feedId} feedEnabled={feedEnabled} />
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Container>
                    }
                </>
            )}

        </div >)
        ;
}
