import React, { useState, useEffect } from 'react';

import {
    Alert,
    Link,
    Chip,
    Tooltip,
    Button,
    Snackbar,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'react-moment';
import ReplayIcon from '@mui/icons-material/Replay';

import { FeedService } from "../../services/dao/feed-service";
import { LoadingComponent } from "../../components/loading-provider";

const logger = new Logger("FeedTargetsDataGrid");
const feedService = new FeedService();

export const FeedTargetsDataGrid = (props) => {
    const feedId = props.feedId;
    const feedEnabled = props.feedEnabled;
    const [loading, setLoading] = useState(true);
    const [targets, setTargets] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [snackbarMsg, setSnackbarMsg] = React.useState({ msg: '', severity: 'success' });
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        feedService.getFeedTargets(feedId).then((response) => {
            const targets = response;
            setTargets(targets);
            setLoading(false);

        });
    }, []);

    const handleReAnalyzeTargets = (event) => {
        setLoading(true);
        feedService.analyzeFeedTargets(
            JSON.stringify({ target_ids: selectionModel })).then((analyzedTargets) => {
                if (analyzedTargets.error) {
                    setLoading(false);
                    setSnackbarMsg({
                        msg: `${analyzedTargets.error || 'Unknown error'}. Ensure targets selected have been analyzed already.`,
                        severity: 'error',
                    });
                    setSnackbarOpen(true);
                } else {
                    setSnackbarMsg({
                        msg: `Set ${analyzedTargets.length} targets for re-analysis`,
                        severity: 'success',
                    });
                    setSnackbarOpen(true);
                }
                feedService.getFeedTargets(feedId).then((response) => {
                    const targets = response;
                    setTargets(targets);
                    setLoading(false);
                });
            });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            {loading ? <LoadingComponent /> : (
                <>
                    {!feedEnabled && !targets?.length && <Alert severity="error">This feed is currently disabled. Maybe you want to <Link href={`/feeds/${feedId}`} >enable it</Link> to start seeing targets?</Alert>}
                    {targets?.length ? (
                        <>
                            <Button
                                align="right"
                                variant="contained"
                                size="small"
                                disabled={selectionModel.length === 0}
                                startIcon={<ReplayIcon />}
                                onClick={handleReAnalyzeTargets}
                            >
                                {`Re-Analyze ${selectionModel.length} Targets`}
                            </Button>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    checkboxSelection
                                    onRowSelectionModelChange={(newRowSelectionModel) => {
                                        setSelectionModel(newRowSelectionModel);
                                    }}
                                    rowSelectionModel={selectionModel}
                                    rows={targets}
                                    columns={[
                                        { field: 'filename', headerName: 'Source Object', flex: 1 },
                                        { field: 'hash', headerName: 'Hash', flex: 0.5 },
                                        {
                                            field: 'created_date',
                                            headerName: 'First Seen',
                                            renderCell: (params) => (
                                                <Tooltip title={<Moment utc local fromNow>{params.value}</Moment>}>
                                                    <Chip label={
                                                        <Moment utc local format="YYYY/MM/DD HH:mm:ss">{params.value}</Moment>
                                                    } />
                                                </Tooltip>
                                            ),
                                            flex: 0.5,
                                        },
                                        {
                                            field: 'updated_date',
                                            headerName: 'Last Analyzed',
                                            renderCell: (params) => params.value ? (
                                                <Tooltip title={<Moment utc local fromNow>{params.value}</Moment>}>
                                                    <Chip label={
                                                        <Moment utc local format="YYYY/MM/DD HH:mm:ss">{params.value}</Moment>
                                                    } />
                                                </Tooltip>
                                            ) : (
                                                <Chip label="Not analyzed" />
                                            ),
                                            flex: 0.5,
                                        },
                                        {
                                            field: 'analyzed',
                                            headerName: 'Analysis Complete?',
                                            valueGetter: (value, row) => {
                                                return value ? 'Yes' : 'No';
                                            },
                                            flex: 0.5,
                                        },
                                    ]}
                                />
                            </div>
                        </>
                    ) : (
                        <Typography>No targets yet!</Typography>
                    )}
                </>

            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleClose}

            >
                <Alert
                    onClose={handleClose}
                    severity={snackbarMsg.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMsg.msg}
                </Alert>
            </Snackbar>
        </>
    );
}
