const prod = {
    appUrl: "https://efft2oydhb.execute-api.us-east-2.amazonaws.com/api",
    logLevel: 'INFO',
};

const dev = {
    appUrl: "http://localhost:8000",
    logLevel: 'INFO',
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
