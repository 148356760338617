const awsConfig = {
    aws_app_analytics: 'enable',

    aws_user_pools: 'enable',
    aws_user_pools_id: 'us-east-2_PwsmFWjKk',
    aws_user_pools_mfa_type: 'OFF',
    aws_user_pools_web_client_id: '6kfdkdm6jgi7l651vp4qftrvgo',
    aws_user_settings: 'enable',
};

export default awsConfig
