import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Container, ListItemButton } from "@mui/material";
import { Logger } from "@aws-amplify/core";
import {
    Typography,
    Link,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import FolderIcon from '@mui/icons-material/Folder';
import Moment from 'react-moment';

import { FeedService } from "../../services/dao/feed-service";
import { useLoading, LoadingComponent } from "../../components/loading-provider";

const logger = new Logger("Dashboard");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const feedService = new FeedService();

export const Dashboard = () => {
    const [feeds, setFeeds] = useState([]);
    const { loading, setLoading } = useLoading();
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        feedService.getFeeds().then((response) => {
            const feeds = response;
            setFeeds(feeds);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }, []);

    const handleFeedToggle = (f) => (event) => {
        feedService.updateFeed(
            f.id, JSON.stringify(
                { enabled: event.target.checked }
            )).then((response) => {
                setFeeds(feeds.map(feed => {
                    if (feed.id === f.id) {
                        return response;
                    } else {
                        // No changes
                        return feed;
                    }
                }));
            });;
    };

    return (
        <div className={classes.root}>
            <Container
                justifycontent="center"
                alignitems="center"
                spacing={1}
                xl={12}
                sm={12}
                sx={{ p: 1 }}
                style={{ width: "100%" }}
            >
                <ThemeProvider theme={theme}>
                    <Typography variant="h3">My Data Feeds</Typography>
                </ThemeProvider>
                {loading ? <LoadingComponent /> : (
                    <List>
                        {feeds?.length ? feeds.map((f) => (
                            <ListItem key={f.id}>
                                <ListItemButton component="a" href={`/feeds/${f.id}`}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={f.name}
                                        secondary={`S3 Source: ${f.src}`}
                                    />
                                    {f.last_health_check ? (
                                        <Tooltip title={<Moment fromNow utc local>{f.last_health_check}</Moment>}>
                                            <Chip color={f.healthy ? "success" : "error"} label={
                                                <Moment utc local format="[Last checked:] YYYY/MM/DD HH:mm:ss">{f.last_health_check}</Moment>
                                            } />
                                        </Tooltip>
                                    ) : (
                                        <Chip label="Feed health not available yet!" />
                                    )
                                    }

                                </ListItemButton>
                                <Switch
                                    edge="end"
                                    onChange={handleFeedToggle(f)}
                                    checked={f.enabled}
                                />
                            </ListItem>
                        )) : (
                            <Typography>No feeds created yet. <Link href="/createFeed">Create a feed</Link> then check back here!</Typography>
                        )}
                    </List>
                )}
            </Container>

        </div>)
        ;
}
