import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {
    Link as RouterLink,
} from 'react-router-dom';


function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

export const BreadCrumbs = ({ links }) => (
    <Breadcrumbs aria-label="breadcrumb">
        <LinkRouter underline="hover" color="inherit" to="/">
            My Feeds
        </LinkRouter>
        {Object.entries(links).map(([label, to]) => {
            return to ? (
                <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                    {label}
                </LinkRouter>
            ) : (
                <Typography color="text.primary" key={to}>
                    {label}
                </Typography>
            );
        })}
    </Breadcrumbs>
);