import { Logger } from "@aws-amplify/core";
import { config } from "../constants";
import { AuthService } from "../../screens/auth/service/auth-service";

const logger = new Logger("FeedService");

export class FeedService {

    getFeeds = async () => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds`

        logger.debug("getFeeds() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feeds', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feeds", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getFeed = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}`

        logger.debug("getFeed() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feed', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feed", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    deleteFeed = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}`

        logger.debug("deleteFeed() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'DELETE',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then((response) => {
                if (response.status === 204) {
                    logger.debug('deleted feed', feedId) // access json.body here
                } else {
                    logger.error("Couldn't delete feed", ex)
                }
            });
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getFeedPatterns = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}/patterns`

        logger.debug("getFeedPatterns() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feed patterns', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feed patterns", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getFeedMetrics = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}/metrics`

        logger.debug("getFeedMetrics() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feed metrics', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feed metrics", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    updateFeed = async (feedId, requestBody) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}`

        logger.debug("updateFeed() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'PATCH',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json',
                },
                mode: "cors",
                body: requestBody,
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('updated feed', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't update feed", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    createFeed = async (createFeedRequest) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds`

        logger.debug("createFeed() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json',
                },
                mode: "cors",
                body: createFeedRequest,
            }).then(response =>
                response.json()
            ).then(json => {
                logger.debug('created feed', JSON.stringify(json)) // access json.body here
                return json;
            }).catch(function (ex) {
                logger.error("Couldn't create feed", ex)
            })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    feedHealthCheck = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}/health`

        logger.debug("getFeedHealth() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feed health', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feed health", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getFeedResults = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}/results`

        logger.debug("getFeedResults() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feed results', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feed results", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    getFeedTargets = async (feedId) => {

        let response = null;
        const endpoint = `${config.appUrl}/feeds/${feedId}/targets`

        logger.debug("getFeedTargets() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                },
                mode: "cors"
            }).then(response =>
                response.json())
                .then(json => {
                    logger.debug('got feed targets', JSON.stringify(json)) // access json.body here
                    return json;
                }).catch(function (ex) {
                    logger.error("Couldn't get feed targets", ex)
                })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

    analyzeFeedTargets = async (analyzeFeedTargetsRequest) => {

        let response = null;
        const endpoint = `${config.appUrl}/targets/analyze`

        logger.debug("analyzeFeedTargets() URL: " + endpoint);
        const idJwtToken = await AuthService.getIdTokenOfCurrentUser();
        if (idJwtToken)
            return await fetch(endpoint, {
                crossDomain: true,
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + idJwtToken,
                    'Content-Type': 'application/json',
                },
                mode: "cors",
                body: analyzeFeedTargetsRequest,
            }).then(response =>
                response.json()
            ).then(json => {
                logger.debug('scheduled targets', JSON.stringify(json)) // access json.body here
                return json;
            }).catch(function (ex) {
                logger.error("Couldn't schedule targets", ex)
            })
        logger.debug("Final response: " + JSON.stringify(response));
        return response;
    }

}
