import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Container,
    Box,
    Button,
    TextField,
    Grid,
    Snackbar,
    Link,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FeedService } from "../../services/dao/feed-service";
import { PatternService } from "../../services/dao/pattern-service";
import { useLoading, LoadingComponent } from "../../components/loading-provider";

const logger = new Logger("CreatePattern");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const feedService = new FeedService();
const patternService = new PatternService();

export const CreatePattern = () => {
    const classes = useStyles();
    const { loading, setLoading } = useLoading();
    const { creatingPattern, setCreatingPattern } = React.useState(false);
    const [feeds, setFeeds] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState('');
    const history = useHistory();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Pattern name is required'),
        feed_id: Yup.string().required('Feed is required'),
        regex: Yup.string().required('Initial pattern regex is required'),
    });
    const { register, setValue, handleSubmit, formState: { errors } } = useForm(
        {
            mode: "onChange",
            resolver: yupResolver(validationSchema)
        });

    React.useEffect(() => {
        setLoading(true);
        feedService.getFeeds().then((response) => {
            const feeds = response;
            setFeeds(feeds);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }, []);

    const onSubmit = data => {
        setCreatingPattern(true);
        const { regex, ...patternData } = data;
        patternService.createPattern(JSON.stringify(patternData)).then(pattern => {
            patternService.createPatternVersion(pattern.id, regex).then(pv => {
                history.push(`/feeds/${data.feed_id}`);
            }).catch(pv_error => {
                setOpen(true);
                setSnackbarMsg('Error creating pattern regex');
                setCreatingPattern(false);
            });
        }).catch(error => {
            setOpen(true);
            setSnackbarMsg('Error creating pattern');
            setCreatingPattern(false);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            {loading ? <LoadingComponent /> : (
                <>
                    {feeds?.length ? (
                        <Container
                            justifycontent="center"
                            alignitems="center"
                            spacing={5}
                            xl={12}
                            sm={12}
                            sx={{ p: 5 }}
                            style={{ width: "100%" }}
                        >
                            <Box px={3} py={2}>
                                <Typography variant="h3">
                                    Create Pattern
                                </Typography>

                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                What is a pattern?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    A <strong>pattern</strong> is an <a href="https://en.wikipedia.org/wiki/Regular_expression">regular expression</a> that you want to analyze your data against.
                                                    A pattern also consists of a name that is also reported when results are found. We recommend using a site like <a href="https://regex101.com/">regex101.com</a> to test patterns before creating them here.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="name"
                                            name="name"
                                            label="Pattern Name"
                                            fullWidth
                                            margin="dense"
                                            {...register('name')}
                                            error={errors.name ? true : false}
                                        />
                                        <Typography variant="inherit" color="textSecondary">
                                            {errors.name?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="regex"
                                            name="regex"
                                            label="Regular Expression"
                                            fullWidth
                                            margin="dense"
                                            {...register('regex')}
                                            error={errors.regex ? true : false}
                                        />
                                        <Typography variant="inherit" color="textSecondary">
                                            {errors.regex?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="feed-id-label">Select Feed</InputLabel>
                                            <Select
                                                labelId="feed-id-label"
                                                id="feed_id"
                                                label="Select Feed"
                                                defaultValue=""
                                                {...register('feed_id')}
                                                onChange={(e) => setValue('feed_id', e.target.value, { shouldValidate: true })}
                                            >
                                                {feeds && feeds.map((f) => (
                                                    <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>
                                                ))}
                                            </Select>
                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.feed_id?.message}
                                            </Typography>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Box mt={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={creatingPattern}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Create Pattern
                                    </Button>
                                </Box>
                            </Box>
                        </Container>

                    ) : (
                        <Alert severity="error">No feeds currently exist. <Link href="/createFeed">Create a feed</Link> first!</Alert>
                    )}
                </>
            )}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snackbarMsg}
            />
        </div >)
        ;
}
