import { Link, Route, Switch } from "react-router-dom";
import { Container } from "@mui/material";
import { ProtectedRoute } from "./screens/auth/auth-screen";
import React from "react";
import { Feed } from "./screens/feeds/feed";
import { Dashboard } from "./screens/dashboard/dashboard";
import { Pattern } from "./screens/feeds/pattern";
import { CreateFeed } from "./screens/feeds/create-feed";
import { CreatePattern } from "./screens/feeds/create-pattern";
import { FeedResults } from "./screens/feeds/feed-results";
import { FeedTargets } from "./screens/feeds/feed-targets";
import { InfoPage } from "./screens/info-page";

export function Routes() {


    return <Switch>
        <Route
            path='/info'
            component={InfoPage}
        />

        <ProtectedRoute
            exact path='/'
            component={Dashboard}
        />

        <ProtectedRoute
            path='/feeds/:feedId'
            component={Feed}
        />

        <ProtectedRoute
            path='/feedResults/:feedId'
            component={FeedResults}
        />

        <ProtectedRoute
            path='/feedTargets/:feedId'
            component={FeedTargets}
        />

        <ProtectedRoute
            path='/patterns/:patternId'
            component={Pattern}
        />

        <ProtectedRoute
            path='/createFeed'
            component={CreateFeed}
        />

        <ProtectedRoute
            path='/createPattern'
            component={CreatePattern}
        />

        <Route path="*">
            <Container
                justifycontent="center"
                alignitems="center"
                spacing={1}
                xl={12}
                sm={12}
                sx={{ p: 1 }}
                style={{ width: "100%" }}
            >
                <h3>
                    Couldn't find <code>{window.location.pathname}</code>
                    <br />
                    Maybe you want to go <Link to="/">home?</Link>
                </h3>
            </Container>
        </Route>

    </Switch>
}
