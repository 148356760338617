import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import {
    Alert,
    Container,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Box,
    TextField,
    FormGroup,
    Switch,
    FormControlLabel,
    Grid,
    Chip,
    Tooltip,
    Paper,
    Stack,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Moment from 'react-moment';

import { FeedService } from "../../services/dao/feed-service";
import { PatternList } from "./pattern-list";
import { useLoading, LoadingComponent } from "../../components/loading-provider";
import { FeedMetrics } from "./feed-metrics";
import { BreadCrumbs } from '../shared/components/app-breadcrumbs';

const logger = new Logger("Feed");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const feedService = new FeedService();

export const Feed = () => {
    const [feed, setFeed] = useState({});
    const { loading, setLoading } = useLoading();
    const [feedName, setFeedName] = useState('');
    const [feedSrc, setFeedSrc] = useState('');
    const [feedTest, setFeedTest] = useState({
        visible: false,
        severity: 'error',
        message: 'Connection to feed failed! Please check bucket permissions.',
    });
    const history = useHistory();
    const [deleteFeedConfirm, setDeleteFeedConfirm] = useState(false);
    const classes = useStyles();
    const { feedId } = useParams();

    useEffect(() => {
        setLoading(true);
        feedService.getFeed(feedId).then((response) => {
            const feed = response;
            setFeed(feed);
            setFeedName(feed.name);
            setFeedSrc(feed.src);
            setLoading(false);
        });
    }, [])


    const handleFeedToggle = (event) => {
        feedService.updateFeed(
            feed.id, JSON.stringify(
                { enabled: event.target.checked }
            )).then((response) => {
                setFeed(response);
            });;
    };

    const handleUpdateFeedName = (event) => {
        if (event.target.value) {
            setFeedName(event.target.value);
            feedService.updateFeed(
                feed.id, JSON.stringify(
                    { name: event.target.value }
                ));
        }
    };

    const handleUpdateFeedSrc = (event) => {
        if (event.target.value) {
            setFeedSrc(event.target.value);
            feedService.updateFeed(
                feed.id, JSON.stringify(
                    { src: event.target.value }
                ));
        }
    };

    const deleteFeed = () => {
        feedService.deleteFeed(
            feedId,
        ).then(response => {
            history.push('/');
        }).finally(() => {
            setDeleteFeedConfirm(false);
        });
    };

    const testFeed = () => {
        setFeedTest({
            visible: true,
            severity: "warning",
            message: "Testing feed, please wait..."
        });
        feedService.feedHealthCheck(
            feedId,
        ).then(response => {
            if (!response.healthy) {
                setFeedTest({
                    visible: true,
                    severity: "error",
                    message: "Connection to feed failed! Please check bucket permissions."
                });
            } else {
                setFeedTest({
                    visible: true,
                    severity: "success",
                    message: "Feed connection successful!"
                });
            }
        }).catch(error => {
            // TODO
        });
    };

    return (
        <div className={classes.root}>
            {loading ? <LoadingComponent /> : (
                <>
                    {feed &&
                        <Container
                            justifycontent="center"
                            alignitems="center"
                            spacing={5}
                            xl={12}
                            sm={12}
                            sx={{ p: 5 }}
                            style={{ width: "100%" }}
                        >
                            <Grid container spacing={2} rowSpacing={2}>
                                <Grid xs={12} item>
                                    <BreadCrumbs links={{ [feedName]: '' }} />
                                    <ThemeProvider theme={theme}>
                                        <Typography variant="h3">{`Feed - ${feedName}`}</Typography>
                                    </ThemeProvider>
                                </Grid>
                                <Grid xs={12} item>
                                    {feedTest?.visible &&
                                        <Alert severity={feedTest.severity}>{feedTest.message}</Alert>
                                    }
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                                        }}
                                        autoComplete="off"
                                    >
                                        <TextField
                                            required
                                            id="name"
                                            label="Feed Name"
                                            value={feedName}
                                            onBlur={handleUpdateFeedName}
                                            onChange={(e) => { setFeedName(e.target.value) }}
                                        />
                                        <TextField
                                            required
                                            id="src"
                                            label="S3 Source Bucket Name"
                                            value={feedSrc}
                                            onBlur={handleUpdateFeedSrc}
                                            onChange={(e) => { setFeedSrc(e.target.value) }}
                                        />
                                    </Box>
                                    <FormGroup>
                                        <FormControlLabel required control={
                                            <Switch
                                                checked={!!feed.enabled}
                                                onChange={handleFeedToggle}
                                                name="enabled"
                                            />
                                        }
                                            label="Enabled?" />
                                    </FormGroup>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href={`/feedResults/${feedId}`}
                                        >
                                            View Feed Results
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={feedTest.severity === "warning"}
                                            onClick={testFeed}
                                        >
                                            Test Feed connectivity
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => setDeleteFeedConfirm(true)}
                                        >
                                            Delete Feed
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid xs={12} item>
                                    {feed.healthy &&
                                        <Chip label="healthy" color="success" />
                                    }
                                    {feed.healthy === false &&
                                        <Chip label="unhealthy" color="error" />
                                    }
                                    {feed.healthy === null &&
                                        <Chip label="unknown" color="warning" />
                                    }
                                    {feed.last_health_check ?
                                        <Tooltip title={<Moment utc local fromNow>{feed.last_health_check}</Moment>}>
                                            <Chip label={
                                                <Moment utc local format="[Last Health Check: ]YYYY/MM/DD HH:mm:ss">{feed.last_health_check}</Moment>
                                            } />
                                        </Tooltip>
                                        :
                                        <Chip label="Feed health not available yet" />
                                    }
                                    <FeedMetrics feedId={feedId} />
                                </Grid>
                                <Grid xs={12} item>
                                    <Paper sx={{ p: 5 }}>
                                        <Typography variant="h4">Patterns</Typography>
                                        {feed.id &&
                                            <PatternList feed={feed} />
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Container>
                    }
                </>
            )}
            <Dialog
                open={deleteFeedConfirm}
                onClose={() => setDeleteFeedConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Feed
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this feed? This action is non-reversible and all results will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteFeedConfirm(false)}>Cancel</Button>
                    <Button onClick={deleteFeed} autoFocus>
                        Yes, delete
                    </Button>
                </DialogActions>
            </Dialog>

        </div >)
        ;
}
