import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import {
    Container,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Box,
    TextField,
    FormGroup,
    Switch,
    FormControlLabel,
    Grid,
    Paper,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextareaAutosize from 'react-textarea-autosize';

import { PatternService } from "../../services/dao/pattern-service";
import { useLoading, LoadingComponent } from "../../components/loading-provider";
import { PatternResults } from './pattern-results';
import { BreadCrumbs } from '../shared/components/app-breadcrumbs';

const logger = new Logger("Pattern");
const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),

    }
}));

const patternService = new PatternService();

export const Pattern = () => {
    const [patternEnabled, setPatternEnabled] = useState(true);
    const { loading, setLoading } = useLoading();
    const [patternName, setPatternName] = useState('');
    const [feedId, setFeedId] = useState('');
    const [patternCurrentRegex, setPatternCurrentRegex] = useState('');
    const [deletePatternConfirm, setDeletePatternConfirm] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const { patternId } = useParams();

    useEffect(() => {
        setLoading(true);
        patternService.getPattern(patternId).then((response) => {
            setPatternName(response.name);
            setPatternEnabled(response.enabled);
            setFeedId(response.feed_id);
            if (response.pattern_versions.length) {
                setPatternCurrentRegex(response.pattern_versions[0].regex);
            }
            setLoading(false);
        });
    }, []);

    const handlePatternToggle = (event) => {
        setPatternEnabled(event.target.checked);
        patternService.updatePattern(
            patternId, JSON.stringify(
                { enabled: event.target.checked }
            ));
    };

    const handleUpdatePatternName = (event) => {
        setPatternName(event.target.value);
        if (event.type === 'blur') {
            if (event.target.value) {
                patternService.updatePattern(
                    patternId, JSON.stringify(
                        { name: event.target.value }
                    ))
            }
        }
    }

    const handleUpdatePatternRegex = (event) => {
        setPatternCurrentRegex(event.target.value);
        if (event.type === 'blur') {
            if (event.target.value) {
                patternService.createPatternVersion(
                    patternId, event.target.value
                );
            }
        }
    }

    const deletePattern = () => {
        patternService.deletePattern(
            patternId
        ).then(response => {
            history.push(`/feeds/${feedId}`);
        }).finally(() => {
            setDeletePatternConfirm(false);
        });
    };

    return (
        <div className={classes.root}>
            {loading ? <LoadingComponent /> : (
                <>
                    {patternName &&
                        <Container
                            justifycontent="center"
                            alignitems="center"
                            spacing={5}
                            xl={12}
                            sm={12}
                            sx={{ p: 5 }}
                            style={{ width: "100%" }}
                        >
                            <Grid container spacing={2} rowSpacing={2}>
                                <Grid xs={12} item>
                                    <BreadCrumbs links={{ [`Feed ${feedId}`]: `/feeds/${feedId}`, 'Pattern Results': '' }} />
                                    <ThemeProvider theme={theme}>
                                        <Typography variant="h3">{`Pattern - ${patternName}`}</Typography>
                                    </ThemeProvider>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                                        }}
                                        autoComplete="off"
                                    >
                                        <TextField
                                            required
                                            id="name"
                                            label="Pattern Name"
                                            value={patternName || ''}
                                            onBlur={handleUpdatePatternName}
                                            onChange={handleUpdatePatternName}
                                        />
                                        <div>
                                            <label style={{ display: 'block' }}>Regular Expression
                                            </label>
                                            <TextareaAutosize
                                                name="regex"
                                                style={{ display: 'block' }}
                                                rows={4}
                                                value={patternCurrentRegex || ''}
                                                onBlur={handleUpdatePatternRegex}
                                                onChange={handleUpdatePatternRegex}
                                            />
                                        </div>
                                    </Box>
                                    <FormGroup>
                                        <FormControlLabel required control={
                                            <Switch
                                                onChange={handlePatternToggle}
                                                checked={patternEnabled}
                                                name="enabled"
                                            />
                                        }
                                            label="Enabled?" />
                                    </FormGroup>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => setDeletePatternConfirm(true)}
                                    >
                                        Delete Pattern
                                    </Button>
                                </Grid>
                                <Grid xs={12} item>
                                    <Paper sx={{ p: 5 }}>
                                        <Typography variant="h4">Results</Typography>
                                        <PatternResults patternId={patternId} />
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Container>
                    }
                </>
            )}
            <Dialog
                open={deletePatternConfirm}
                onClose={() => setDeletePatternConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Pattern
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this pattern? This action is non-reversible and all results will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeletePatternConfirm(false)}>Cancel</Button>
                    <Button onClick={deletePattern} autoFocus>
                        Yes, delete
                    </Button>
                </DialogActions>
            </Dialog>

        </div >)
        ;
}
